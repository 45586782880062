<template>
	<div class="flexPage themePage">
		<navbar :params="pageInfo"></navbar>
		<div class="flexContent">
			<!-- 全部 -->
			<nodata v-if="!listNode || listNode.length == 0" />
			<div class="orderListBoxs" v-for="item in listNode" v-else>
				<div class="order">
					<span>{{ $t('other.translate45') }}: {{ item.id }}</span>
					<div class="statusNode" v-if="item.status == 3">{{ $t('other.translate65') }}</div>
					<div class="statusNodeF" v-if="item.status == 2">{{ $t('other.translate66') }}</div>
					<div class="statusNodeW" v-if="item.status == 0">{{ $t('other.translate67') }}</div>
				</div>
				<div style="height: 90px;display: flex;flex-wrap: wrap;align-content: space-between">
					<div class="orders">
						<span>{{ $t('other.translate68') }}: </span>
						<span>{{ item.address }}</span>
						<img src="../../assets/img/copy.png" @click="$mts.copy(item.address)" alt=""
							style="width: 24px;height: 24px;margin-left: 5px;">
					</div>
					<div class="orders">
						<span>{{ $t('other.translate69') }}: </span>
						<span>{{ item.coin.name }}</span>
					</div>
					<div class="orders">
						<span>{{ $t('other.translate53') }}: </span>
						<span>{{ item.arrivedAmount }}</span>
					</div>
					<div class="orders">
						<span>{{ $t('other.translate70') }}: </span>
						<span>{{ item.createTime }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";
export default {
	data() {
		return {
			pageInfo: {
				title: this.$t('form.assets.zzResult'),
				icon: 'orders-o',
				navbarStyle: 'normal',
				value: ''
			},
			typeActive: 0,
			tabs: [
				{ title: this.$t('trade.all'), active: 'active' },
				{ title: this.$t('otc.order.pending'), active: '' },
				{ title: this.$t('otc.order.completed'), active: '' }
			],
			pageNum: 1, // 当前页码
			pageSize: 10, // 当前页码
			submiting: false,
			listNode: []
		}
	},
	components: { navbar },
	watch: {},
	computed: {
		list() {
			return this.$store.state.app.vanList;
		},
		finished() {
			return this.$store.state.app.finished;
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		onChooseTab(index) {
			this.typeActive = index;
			this.refresh();
		},
		refresh() {
			this.pageNum = 1;
			this.$mts.initPage();
			this.$store.state.app.loading = true;

		},
		query() {
			this.$http.post(this.host + '/uc/asset/transaction', { pageNo: this.pageNum, pageSize: this.pageSize, type: 2 }).then(res => {
				this.listNode = res.body.data.content
			});

		},
		formatStatus(status) {
			switch (status) {
				case "CONFIRM":   //已到账
					return this.$t('otc.order.completed');
					break;
				default:
					return this.$t('otc.order.pending');
					break;
			}
		},
	}
}
</script>
<style scoped>
.statusNodeW {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #0052FF33;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #0052FF;

}

.statusNodeF {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #EB433533;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #EB4335;

}

.statusNode {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #3AD29F33;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #3AD29F;

}

.orders span:first-child {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	color: #00000080;
	margin-right: 8px
}

.orders span:last-child {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	color: #000;

}

.orders {
	width: 100%;
	display: flex;
	align-items: center
}

.order {
	width: 100%;
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.orderListBoxs {
	width: 100%;
	padding: 0 16px;
	margin-top: 17px;
	border-bottom: 1px solid #0000001A;
	padding-bottom: 16px
}
</style>